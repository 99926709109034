<template>
  <el-container
    height="100%"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-aside class="navbox" width="100px" height="100%">
      <my-nav></my-nav>
    </el-aside>
    <el-aside class="navsec" :width="navSecWidth" height="100%" v-show="isShow">
      <navsec></navsec>
    </el-aside>
    <el-main class="maincon">
      <div class="mainCont"><router-view/></div>
    </el-main>
  </el-container>
</template>

<script>
import myNav from '@/components/nav/nav.vue'
import navsec from '@/components/nav/navSec.vue'
import cookie from '@/assets/getCookie.js'
import { mapMutations, mapGetters } from 'vuex'
import { isFirstHpStyleByWebsiteId, initHpStyleByWebsiteId } from '@/api/local.js'
export default {
  name: 'Home',
  data() {
    return {
      objData: {

      },
      loading: true,
      userId: '',
      navSecWidth: '140px', // 二级菜单宽度
      navSecData: {},
      isShow: true
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        console.log('path', newVal)
        if (newVal.path === '/netmanage/init') {
          this.isShow = false
        } else {
          this.isShow = true
        }
      },
      deep: true
    }
  },
  components: {
    myNav,
    navsec
  },
  computed: {
    ...mapGetters(['indexData'])
  },
  created() {
    if (this.$route.path === '/netmanage/init') {
      this.isShow = false
    } else {
      this.isShow = true
    }
  },
  mounted() {
    this.isFirstHpStyleByWebsiteId(this.indexData.websiteId)
    this.userId = cookie.getCookie('AXY_' + this.indexData.websiteId + '_user_id')
    console.log(this.userId, this.indexData.siteUrl, 'this.userIdthis.userIdthis.userId')
    this.setManageData(this.userId)
  },
  methods: {
    ...mapMutations(['SET_USERDATA']),
    isFirstHpStyleByWebsiteId(websiteId) {
      console.log('jjjj')
      const params = {
        website_id: websiteId
      }
      isFirstHpStyleByWebsiteId(params).then((res) => {
        if (res.message === 'success') {
          // 未初始化
          if (res.data) {
            this.init(websiteId)
          } else {
            this.loading = false
          }
          // this.SET_FIRSTINIT(res.data)
        }
      })
    },
    init(website_id) {
      const params = {
        website_id: website_id
      }
      initHpStyleByWebsiteId(params).then((res) => {
        if (res.message === 'success') {
          if (res.data) {
            setTimeout(() => {
              this.loading = false
            }, 200)
            console.log('kkkkk')
            // this.$toast('初始化成功')
          } else {
            this.loading = false
            this.$toast('系统错误，请联系管理员!')
          }
        }
      })
    },
    // 判断是否登录
    setManageData(userId) {
      if (userId && userId !== 'null') {
        const userName = decodeURI(escape(cookie.getCookie('AXY_' + this.indexData.websiteId + '_user_name')))
        const schoolId = cookie.getCookie('AXY_' + this.indexData.websiteId + '_school_id')
        const userType = cookie.getCookie('AXY_' + this.indexData.websiteId + '_user_type')
        const thirdToken = cookie.getLoginCookie('AXY_' + this.indexData.websiteId + '_token').replace(/"/g, '')
        const adminType = cookie.getCookie('AXY_' + this.indexData.websiteId + '_adminType')
        const accessToken = cookie.getCookie('AXY_' + this.indexData.websiteId + '_access_token')
        const manageData = {
          userName: userName,
          schoolId: schoolId,
          userId: userId,
          userType: userType,
          accessToken: accessToken,
          thirdToken: thirdToken,
          adminType: adminType
        }
        // 将用户信息保存
        this.SET_USERDATA(manageData)
        localStorage.setItem('manageData', JSON.stringify(manageData))
        // if (adminType !== '1') {
        //   this.$router.push({ path: '/mynews/newsmanagement' })
        // }
      } else {
        window.location.href = this.indexData.siteUrl
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .el-aside{
    position: relative;
  }
  .el-container{
    height: 100%;
  }
  .maincon {
    padding: 0;
  }
  .navbox {

    overflow: hidden;
    /* 设置滚动条的样式 */
  }

  .navsec {
    margin-right: 15px;
    overflow: hidden;
  }
</style>
