<template>
  <div class="nav">
    <div>
      <div class="logoSite">
        <a href="/"><img src="/images/nav/logo.png" alt="" /></a>
      </div>
      <div class="nav_list" >
        <router-link
          class="nav_item"
          v-for="(item, index) in navInfo"
          :key="index"
          :to="{ path: item.path}"
        >
          <div class="nav_item_r" @click="tonavSec(item)">
            <div class="nav_item_img">
              <img class="imgP" :src="item.srcP" alt="">
              <img class="imgN" :src="item.srcN" alt="">
            </div>
            <div class="nav_title">{{ item.name }}</div>
          </div>
          <div class="pointer"></div>
        </router-link>
        <router-link
          v-if="indexData.websiteId == '50'"
          class="nav_item"
          :to="{ path: '/application'}"
        >
          <div class="nav_item_r" @click="tonavSec1">
            <div class="nav_item_img">
              <img class="imgP" src="/images/nav/app_h.png" alt="">
              <img class="imgN" src="/images/nav/app.png" alt="">
            </div>
            <div class="nav_title">应用中心</div>
          </div>
          <div class="pointer"></div>
        </router-link>
      </div>
    </div>
    <div class="botnavbox">
      <div class="backhome nav_item" @click="backHome">
        <div class="nav_item_r">
          <div class="nav_item_img">
            <img class="imgN" src="/images/nav/backhome.png" alt="">
            <img class="imgP" src="/images/nav/backhomebg.png" alt="">
          </div>
          <div class="nav_title">返回门户</div>
        </div>
      </div>
      <div class="changeuserbox nav_item">
        <div class="nav_item_r">
          <el-popover
            placement="right"
            :offset="0"
            trigger="hover"
          >
            <template #reference>
              <div class="nav_item_img">
                <img class="imgN" src="/images/nav/changeuser.png" alt="">
                <img class="imgP" src="/images/nav/changeuserbg.png" alt="">
              </div>
              <div class="nav_title">切换身份</div>
            </template>
            <ul class="changeitem">
              <li class="changeteacher" @click="changeTeacher">切换为教师</li>
              <li class="loginout" @click="loginOut">退出</li>
            </ul>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getManageMenuList } from '@/api/local.js'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Mynav',
  data() {
    return {
      navInfoList: [
        {
        // name: '网站管理',
          srcN: '/images/nav/webNormal.png',
          srcP: '/images/nav/webPressed.png',
          path: '/netmanage',
          params: '',
          code: '24A040FA'
        },
        {
          // name: '信息管理',
          srcN: '/images/nav/msgNormal.png',
          srcP: '/images/nav/msgPressed.png',
          path: '/mynews',
          params: '',
          code: '78633C0A'
        }, {
          // name: '人员管理',
          srcN: '/images/nav/humenNormal.png',
          srcP: '/images/nav/humenPressed.png',
          path: '/management',
          params: '',
          code: '000F1E1C'
        }, {
          // name: '数据管理',
          srcN: '/images/nav/dataNormal.png',
          srcP: '/images/nav/dataPressed.png',
          path: '/datamanager',
          params: '',
          code: 'CD0A0B45'
        }, {
          // name: '权限管理',
          srcN: '/images/nav/permission.png',
          srcP: '/images/nav/permissioned.png',
          path: '/permission',
          params: '',
          code: 'JF843KF9'
        }],
      secNavList: [
        {
          code: '68F2CCDE',
          path: '/mynews/newsmanagement'
        }, {
          code: '8611A94C',
          path: '/mynews/showinfo'
        }, {
          code: 'E65FB1CF',
          path: '/mynews/inform'
        }, {
          code: 'DD4A38B0',
          path: '/mynews/resinfo'
        }, {
          code: '0B211C19',
          path: '/netmanage/baseinfomanage'
        }, {
          code: '7AECC9A6',
          path: '/netmanage/pagemanage'
        }, {
          code: '7DB61239',
          path: '/management/structure'
        },
        {
          code: '8D61A536',
          path: '/management/teacher'
        },
        {
          code: '102E7B5C',
          path: '/management/student'
        },
        {
          code: '091532F1',
          path: '/management/class'
        }, {
          code: '62830BAC',
          path: '/datamanager/alldata'
        },
        {
          code: 'B420FF0E',
          path: '/datamanager/alldata'
        },
        {
          code: 'C215E714',
          path: '/datamanager/alldata'
        },
        {
          code: '39D48EA1',
          path: '/datamanager/alldata'
        },
        {
          code: 'DBBA8CFB',
          path: '/datamanager/alldata'
        },
        {
          code: '45A82D0F',
          path: '/datamanager/alldata'
        },
        {
          code: '90388FE5',
          path: '/datamanager/alldata'
        }
      ],
      navInfo: [],
      navSecTitle: '网站管理', // 二级菜单title
      navSecWidth: '0' // 二级菜单宽度
      // 以及导航的信息

    }
  },
  computed: {
    ...mapGetters(['manageData', 'indexData'])
  },
  watch: {
    manageData: {
      handler() {

      },
      deep: true
    }
  },
  mounted() {
    this.getManageMenuListNav()
  },
  methods: {
    ...mapMutations(['SET_DATASTE', 'SET_NAVDATA', 'SET_DATAANALYSIS']),
    backHome() {
      window.location.href = this.indexData.siteUrl
    },
    changeTeacher() {
      const backUrl = window.location.host
      const accessToken = this.manageData.accessToken
      window.location.href = this.$teacherPath + '/loading.html?token=' + accessToken + '&backurl=' + backUrl
    },
    loginOut() {
      localStorage.removeItem('manageData')
      localStorage.removeItem('indexData')
      localStorage.removeItem('store')
      const keys = document.cookie.match(/[^ =;]+(?==)/g)
      if (keys) {
        for (var i = keys.length; i--;) {
          document.cookie = keys[i] + '=null;domain=' + this.indexData.siteDomain
        }
      }
      window.location.href = this.indexData.siteUrl
    },
    getManageMenuListNav() {
      getManageMenuList().then(res => {
        const navDataList = res.data
        console.log(navDataList, 'www')
        for (let i = 0; i < navDataList.length; i++) {
          const navData = navDataList[i]
          const arr = this.navInfoList.filter((v) => {
            return v.code === navData.code
          })
          if (arr && arr.length > 0) {
            const obj = arr[0]
            navDataList[i] = Object.assign(navData, obj)
          }
        }
        // const adminType = this.manageData.adminType
        // console.log(typeof adminType, 'qqqqqq')
        // if (adminType !== '1') {
        //   this.navInfo = navDataList.filter((v) => {
        //     return v.code !== '24A040FA'
        //   })
        // } else {
        //   }
        this.navInfo = navDataList
        // 站内新闻
        // this.navInfo = this.navInfo.filter((v) => {
        //   return v.code !== '24A040FA'
        // })
        console.log(this.navInfo, 'this.navInfo')
        let flag = true
        this.navInfo.forEach((item) => {
          if (item.code === 'CD0A0B45' && this.$route.path.includes('/datamanager/')) {
            flag = false
            this.SET_NAVDATA(item)
          } else if (item.code === '24A040FA' && this.$route.path.includes('/netmanage/')) {
            flag = false
            this.SET_NAVDATA(item)
          } else if (item.code === '78633C0A' && this.$route.path.includes('/mynews/')) {
            flag = false
            this.SET_NAVDATA(item)
          } else if (item.code === '000F1E1C' && this.$route.path.includes('/management/')) {
            flag = false
            this.SET_NAVDATA(item)
          } else if (item.code === 'JF843KF9' && this.$route.path.includes('/permission/')) {
            flag = false
            this.SET_NAVDATA(item)
          } else {
            //   // 动态跳转路由

          }
        })
        if (flag) {
          setTimeout(() => {
            const routeCode = navDataList[0].children[0].code
            this.secNavList.forEach(v => {
              if (v.code === routeCode) {
                console.log(v.path, 'ooooooo')
                this.SET_NAVDATA(navDataList[0])
                this.$router.push({ path: v.path })
              }
            })
          }, 100)
        }
        // if (this.$route.path.includes('/datamanager/')) {
        //   this.navInfo.forEach((item) => {
        //     if (item.code === 'CD0A0B45') {
        //       this.SET_NAVDATA(item)
        //     }
        //   })
        // } else if (this.$route.path.includes('/netmanage/')) {
        //   this.navInfo.forEach((item) => {
        //     if (item.code === '24A040FA') {
        //       this.SET_NAVDATA(item)
        //     }
        //   })
        // } else if (this.$route.path.includes('/mynews/')) {
        //   this.navInfo.forEach((item) => {
        //     if (item.code === '78633C0A') {
        //       this.SET_NAVDATA(item)
        //     }
        //   })
        // } else if (this.$route.path.includes('/management/')) {
        //   this.navInfo.forEach((item) => {
        //     if (item.code === '000F1E1C') {
        //       this.SET_NAVDATA(item)
        //     }
        //   })
        // } else if (this.$route.path.includes('/permission/')) {
        //   this.navInfo.forEach((item) => {
        //     if (item.code === 'JF843KF9') {
        //       this.SET_NAVDATA(item)
        //     }
        //   })
        // } else {
        //   // 动态跳转路由
        //   setTimeout(() => {
        //     const routeCode = navDataList[0].children[0].code
        //     this.secNavList.forEach(v => {
        //       if (v.code === routeCode) {
        //         console.log(v.path, 'ooooooo')
        //         this.SET_NAVDATA(navDataList[0])
        //         this.$router.push({ path: v.path })
        //       }
        //     })
        //   }, 100)
        // }
      })
    },

    tonavSec(obj) {
      console.log(obj, 'sssss')
      this.SET_NAVDATA(obj)
    },
    tonavSec1() {
      const obj = {
        path: '/application',
        name: '应用中心',
        children: [

          {
            path: '/application/alldata',
            name: '所有应用清单',
            code: 1
          },
          {
            path: '/application/alldata',
            name: '待审核',
            code: 2
          },
          {
            path: '/application/alldata',
            name: '已冻结',
            code: 3
          }
        ]
      }
      this.SET_NAVDATA(obj)
    }
  }
}
</script>

<style lang="less" scoped>
  .nav{
    width:100%;
    height: 100%;
    background: #31364D;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &::-webkit-scrollbar {
    width:5px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
    border-radius:10px;
    background:rgba(0,0,0,0.1);
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(0,0,0,0.4);
    }
  }
  .botnavbox {
    flex: 1;
    display: flex;
    padding-bottom: 50px;
    flex-direction: column-reverse;
    min-height: 170px;
    box-sizing: border-box;
  }
  .botnavbox >div {
    cursor: pointer;
    margin-top: 15px;
  }
  .imgP {
    display: none;
  }
  .nav_item_img{
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin: 0 auto;
    border-radius: 15px;
  }
  .nav_item_img img{
    width: 70%;
    vertical-align: middle;
  }
  .router-link-active{
    // .pointer{
    //   width:9px;
    //   height: 50px;
    //   position:absolute;
    //   left: 0;
    //   top: 0;
    //   background: rgba(255, 255, 255, 0.23);
    //   border-radius: 0 5px 5px 0;
    // }
    // .nav_item_img {

    //   background: rgba(255, 255, 255, 0.23);
    // }
     .imgP {
       display: inline-block;
     }
     .imgN {
       display: none;
     }
     .nav_title {
       color: #fff;
     }
  }
  // .nav_item:hover {
  //   .nav_item_img {
  //     background: rgba(255, 255, 255, 0.13);
  //   }
  // }
  .logoSite{
    width: 100%;
    margin-top: 30px;
  }
  .logoSite img{
    margin: 0 auto;
    width: 40px;
  }
  .nav_list{
    margin-top: 30px;
  }
  .nav_list a {
    display: block;
    text-decoration: none;
  }
  .nav_item{
    position: relative;
    margin-bottom: 20px;
  }
  .nav_title{
    font-size:16px;
    color: rgba(255,255,255,.4);
    width: 100%;
    text-align: center;
  }
  .bg{
    background: rgba(255, 255, 255, 0.23);
    border: 1px solid rgba(255,255,255,.4);
  }
  .botnavbox .nav_item {
    margin-bottom: 0;
  }
  .changeitem {
    width: 100px;
  }
  .changeitem li {
   height: 30px;
   color: #999999;
   font-size: 14px;
   line-height: 30px;
   text-align: center;
   cursor: pointer;
   border-bottom: 2px solid #F2F2F2;
   &:hover {
     color: #5096F5;
   }
  }
  .changeitem li:last-of-type{
    border: none;
  }
</style>
