<template>
  <div class="nav_sec">
    <div class="nav_sec_title">{{ navData.name }}</div>
    <div class="nav_sec_home" v-if="navData.code === '78633C0A' || navData.code === '24A040FA' || navData.code === '000F1E1C' || navData.code === 'JF843KF9'">
      <ul>
        <li v-for="(item,index) in infoList" :key="index" class="nav_sec_list" @click="toMain(item)">
          <router-link :to="{ path: item.path }">{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="nav_sec_home" v-else>
      <ul>
        <li v-for="(item,index) in infoList" :key="index" :class="isActive === item.code?'nav_sec_list nav_sec_list_active':'nav_sec_list'">
          <a href="javascript:;" @click="changeUrl(item.code)" v-if="item.code">{{ item.name }}</a>
          <a href="javascript:;" @click="changeUrl(item.code)" v-else>{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Navsec',
  props: {
    objData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      code: '',
      isActive: '62830BAC',
      infoList: [],
      navSecInfo: [{
        code: '68F2CCDE',
        path: '/mynews/newsmanagement'
      }, {
        code: '8611A94C',
        path: '/mynews/showinfo'
      }, {
        code: 'E65FB1CF',
        path: '/mynews/inform'
      }, {
        code: 'DD4A38B0',
        path: '/mynews/resinfo'
      }
      ],
      netSecList: [
        {
          code: '0B211C19',
          path: '/netmanage/baseinfomanage'
        }, {
          code: '7AECC9A6',
          path: '/netmanage/pagemanage'
        }
      ],
      managementList: [
        {
          code: '7DB61239',
          path: '/management/structure'
        },
        {
          code: '8D61A536',
          path: '/management/teacher'
        },
        {
          code: '102E7B5C',
          path: '/management/student'
        },
        {
          code: '091532F1',
          path: '/management/class'
        }
      ],
      dataList: [
        {
          code: '62830BAC',
          path: '/datamanager/alldata'
        },
        {
          code: 'B420FF0E',
          path: '/datamanager/alldata'
        },
        {
          code: 'C215E714',
          path: '/datamanager/alldata'
        },
        {
          code: '39D48EA1',
          path: '/datamanager/alldata'
        },
        {
          code: 'DBBA8CFB',
          path: '/datamanager/alldata'
        },
        {
          code: '45A82D0F',
          path: '/datamanager/alldata'
        },
        {
          code: '90388FE5',
          path: '/datamanager/alldata'
        }
      ],
      navSecData: {}
    }
  },
  computed: {
    ...mapGetters(['datatype', 'navData', 'dataAnalysis'])
  },
  watch: {
    $route: {
      handler(newVal) {
        this.changeNavList()
      },
      deep: true
    },
    navData: {
      handler(newVal) {
        // console.log(newVal, 'newVal')
        this.getNavSec()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.isActive = this.datatype || '62830BAC'
  },
  mounted() {
    // console.log(this.navData)
  },

  methods: {
    ...mapMutations(['SET_DATASTE', 'SET_NAVMAIN']),
    getNavSec() {
      if (this.navData) {
        const navDataList = this.navData.children ? this.navData.children : []
        if (this.navData.code === '78633C0A') {
          this.infoList = this.navSecInfo
        } else if (this.navData.code === 'CD0A0B45') {
          this.infoList = this.dataList
        } else if (this.navData.code === '24A040FA') {
          this.infoList = this.netSecList
        } else if (this.navData.code === '000F1E1C') {
          this.infoList = this.managementList
        }
        for (let i = 0; i < navDataList.length; i++) {
          const navData = navDataList[i]
          const arr = this.infoList.filter((v) => {
            return v.code === navData.code
          })
          if (arr && arr.length > 0) {
            const obj = arr[0]
            navDataList[i] = Object.assign(navData, obj)
          }
        }
        this.infoList = navDataList
        // 信息管理
        if (this.navData.code === '78633C0A') {
          this.infoList = this.infoList.filter((v) => {
            return v.code !== 'DD4A38B0' && v.code !== 'E65FB1CF'
          })
        } else if (this.navData.code === 'CD0A0B45') {
          // 数据管理
          this.infoList = this.infoList.filter((v) => {
            return v.code !== '45A82D0F' && v.code !== '90388FE5'
          })
        }
        this.changeNavList()
        // if (!sessionStorage.getItem('hasSecactive')) {
        //   this.SET_NAVMAIN(this.infoList[0])
        // } else {
        //   sessionStorage.removeItem('hasSecactive')
        // }
      }
    },
    changeNavList() {
      // 根据路由当前位置  输出三级导航
      if (this.$route.path.includes('/baseinfomanage')) {
        this.code = '0B211C19'
      } else if (this.$route.path.includes('/pagemanage')) {
        this.code = '7AECC9A6'
      } else if (this.$route.path.includes('/newsmanagement')) {
        this.code = '68F2CCDE'
      } else if (this.$route.path.includes('/showinfo')) {
        this.code = '8611A94C'
      } else if (this.$route.path.includes('/inform')) {
        this.code = 'E65FB1CF'
      } else if (this.$route.path.includes('/resinfo')) {
        this.code = 'DD4A38B0'
      } else if (this.$route.path.includes('/management')) {
        this.code = '7DB61239'
      } else if (this.$route.path.includes('/permission')) {
        this.code = '8G03MG8C'
      }
      // console.log(this.code, this.infoList, this.$route.path, 'kkkkkkkkkkkkkkkkkk')
      this.infoList.forEach((item) => {
        if (item.code === this.code) {
          this.SET_NAVMAIN(item)
        }
      })
    },
    changeUrl(code) {
      this.isActive = code
      sessionStorage.setItem('isActive', this.isActive)
      // console.log(this.dataAnalysis, 'wwwwww')
      const obj = {
        code: code,
        data: this.dataAnalysis
      }
      this.SET_DATASTE(obj)
    },
    toMain(obj) {
      console.log(obj, 'qqqqqqqqqq')
      this.SET_NAVMAIN(obj)
    }
  }
}
</script>
<style lang="less" scoped>
  .nav_sec{
    width:100%;
    height: 100%;
    background: #fff;
    // border-radius: 0 5px 5px 0;
    position:absolute;
    left: 0;
    top: 50%;
    overflow: auto;
    transform: translateY(-50%);
  }
  .nav_sec_title{
    height: 65px;
    line-height: 45px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #F2F2F2;
    padding: 20px 15px 0;
  }
  .nav_sec_list{
    padding-top: 20px;
    a {
      height: 69px;
      line-height: 69px;
      color:#7B7B7B ;
      display: block;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      text-align: left;
      padding: 0 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.nav_sec_list_active a, a.router-link-active {
      color: #5096F5;
      font-weight: 600;
      background: #F2F2F2;
    }
    a:hover {
      background: #F2F2F2;
    }
  }
</style>
